<template>
  <v-alert
    v-if="fetching"
    class="rounded-lg"
    color="grey"
    outlined
    variant="text"
  >
    <v-skeleton-loader type="list-item-three-line" />
  </v-alert>
  <OverlayLoader v-else :loading="refetching" rounded>
    <div class="flex flex-col gap-2">
      <PublicationAlert
        v-for="(pub, i) in publications"
        :key="i"
        :publication="pub"
        :publish="publish"
        :unpublish="unpublish"
      />
    </div>
  </OverlayLoader>
</template>

<script lang="ts">
import type { PlatformProductType } from "~/layers/admin/types/common";
import PublicationAlert from "./PublicationAlert.vue";

export default defineComponent({
  props: {
    where: { type: Object as PropType<ResourceWhereInput>, required: true },
    type: {
      type: String as PropType<PlatformProductType>,
      required: true,
    },
  },
  components: { PublicationAlert },
  setup(props) {
    const {
      publications,
      fetching,
      refetching,
      publish,
      unpublish,
      fetch,
      refetch,
    } = usePublication(props.type, props.where);

    const { onSaved } = useSaveContext();
    onSaved(refetch);

    onMounted(fetch);

    return {
      publications,
      publish,
      unpublish,
      fetching,
      refetching,
    };
  },
});
</script>
