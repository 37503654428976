import { PublicationStatus } from "~/layers/admin/models";

export const alertType = {
  [PublicationStatus.PUBLISHED]: "success",
  [PublicationStatus.REJECTED]: "error",
};

export const alertColor = {
  [PublicationStatus.PENDING]: "lime darken-1",
  [PublicationStatus.FAILED]: "red",
  // [PublicationStatus.ON_HOLD]: 'grey darken-2',
  [PublicationStatus.IN_REVIEW]: "cyan darken-1",
  [PublicationStatus.ON_HOLD]: "#2A3B4D",
};

export const alertIcon = {
  [PublicationStatus.PENDING]: "pending",
  [PublicationStatus.FAILED]: "error",
  [PublicationStatus.IN_REVIEW]: "visibility",
  [PublicationStatus.PUBLISHED]: "check_circle",
  [PublicationStatus.REJECTED]: "do_not_disturb_on",
  [PublicationStatus.ON_HOLD]: "radio_button_checked",
  [PublicationStatus.PUBLISHING]: "new_releases",
};

export const chipColor = {
  [PublicationStatus.PENDING]: "lime darken-1",
  [PublicationStatus.FAILED]: "red",
  [PublicationStatus.PUBLISHED]: "green",
  [PublicationStatus.REJECTED]: "red",
  [PublicationStatus.PUBLISHING]: "orange",
  [PublicationStatus.IN_REVIEW]: "cyan darken-1",
  [PublicationStatus.ON_HOLD]: "#2A3B4D",
};
