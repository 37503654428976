import gql from "graphql-tag";
import { useApolloClient } from "@vue/apollo-composable";
import dayjs from "dayjs";
import { createHooks } from "hookable";
import { PublicationStatus, PublishedStatus } from "../models";
import { set } from "lodash";
import type { PlatformProductType } from "../types/common";

export interface ResourceWhereInput {
  id?: number | string;
  handle?: string;
}

interface ISalesChannel {
  handle: string;
  name: string;
  offline: boolean;
  allowed_currencies: string[];
  // app: {
  //   handle: string
  //   name: string
  //   short_description?: string
  //   description?: string
  //   integration: string
  // }
}

interface _IPublicationItem {
  updated_at?: string;
  hasUnpublishedChanges: boolean;
  status: PublicationStatus;
  channel: ISalesChannel;
  errorMessages: string[];
  errors: {
    codes: string[];
    messages: Record<string, string[]>;
  };
  schedule?: string;
}

interface IPublication {
  status: PublishedStatus;
  publications: _IPublicationItem[];
}

export interface IPublicationItem extends _IPublicationItem {
  publishInProgress: number;
  isPublishing: boolean;
  isUnpublishing: boolean;
}

type PublicationHooks = {
  published: { (): void };
  unpublished: { (): void };
  updated: { (): void };
};

const queryBody = `
  status
  publications{
    updated_at
    hasUnpublishedChanges
    status
    channel{
      handle
      name
    }
    errors
    errorMessages
    schedule
  }
`;

const simulatePublishProgress = (cb: (progress: number) => void): void => {
  const duration = 1.5 * 60 * 1000; // Total duration, about 1.5 minutes in ms
  const startTime = Date.now();
  const endTime = startTime + duration;
  let progress = 0;

  const updateProgress = () => {
    const elapsedTime = Date.now() - startTime;
    const remainingTime = Math.max(0, endTime - Date.now());

    if (progress >= 100 || remainingTime <= 0) {
      cb(100); // Ensure we reach 100%
      return;
    }

    // Ensure progress is on track to hit 100% exactly at duration
    const idealProgress = (elapsedTime / (duration * 1000)) * 100;
    const maxChunk = Math.min(20, (remainingTime / (duration * 1000)) * 100);
    const randomChunk = Math.min(5 + Math.random() * maxChunk, 100 - progress);

    // Ensure progress doesn't lag too far behind schedule
    progress = Math.max(progress + randomChunk, idealProgress);

    cb(progress);

    // Calculate next update interval dynamically, slowing down near the end
    const nextUpdateDelay = Math.min(500 + Math.random() * 1500, remainingTime);
    setTimeout(updateProgress, nextUpdateDelay);
  };

  updateProgress(); // Start updates
};

export const usePublicationStore = defineStore("publication", () => {
  const events = createHooks<PublicationHooks>();

  const fetching = ref(false);
  const refetching = ref(false);
  const publishInProgress = ref<Record<string, number>>({});
  const publishing = ref<Record<string, boolean>>({});
  const unpublishing = ref<Record<string, boolean>>({});
  const loading = computed(
    () =>
      fetching.value ||
      refetching.value ||
      Object.values(publishing.value).some((x) => x === true) ||
      Object.values(unpublishing.value).some((x) => x === true)
  );

  const _status = ref(PublishedStatus.PENDING);
  const _publications = ref<_IPublicationItem[]>([]);

  const hasUnpublishedChanges = computed(() =>
    _publications.value.some((x) => x.hasUnpublishedChanges)
  );

  const setPublicationData = (data: IPublication) => {
    _status.value = data.status || PublishedStatus.PENDING;
    _publications.value = data.publications || [];
  };

  const dispatch = (event: keyof PublicationHooks) => {
    return events.callHook(event);
  };

  const handle = <E extends keyof PublicationHooks>(
    event: E,
    cb: PublicationHooks[E]
  ) => {
    events.hook(event, cb as any);
    onUnmounted(() => {
      events.removeHook(event, cb as any);
    });
  };

  return {
    fetching,
    refetching,
    publishInProgress,
    publishing,
    unpublishing,
    loading,
    hasUnpublishedChanges,
    _status,
    _publications,
    setPublicationData,
    dispatch,
    handle,
  };
});

export const usePublication = (
  type: PlatformProductType,
  where: ResourceWhereInput
) => {
  const store = usePublicationStore();
  const apollo = useApolloClient();
  const apolloClient = apollo.resolveClient();

  const _fetch = () => {
    return new Promise<IPublication>((resolve, reject) => {
      apolloClient
        .query<{ wsPublication: IPublication }>({
          query: gql`
          query ($type: String!, $where: ResourceWhereInput!) {
            wsPublication(type: $type, where: $where) {
              ${queryBody}
            }
          }
        `,
          fetchPolicy: "no-cache",
          variables: {
            type,
            where,
          },
        })
        .then(({ data }) => {
          store.setPublicationData(data.wsPublication);
          resolve(data.wsPublication);
        })
        .catch(reject);
    });
  };

  const fetch = async () => {
    store.fetching = true;
    await _fetch();
    store.fetching = false;
  };

  const refetch = async () => {
    store.refetching = true;
    await _fetch();
    store.refetching = false;
  };

  const checkStatus = async (channel: string) => {
    const result = await _fetch();
    const publication = findBy(
      result.publications,
      ["channel", "handle"],
      channel
    );

    if (publication) {
      if (publication.status !== PublicationStatus.PUBLISHING) {
        set(store.publishInProgress, channel, 0);
      }
    }
  };

  const dispatchPublished = () => {
    store.dispatch("published");
    store.dispatch("updated");
  };

  const publish = (channel: string, schedule?: Date | string) => {
    set(store.publishInProgress, channel, 0);
    set(store.publishing, channel, true);

    apolloClient
      .mutate({
        mutation: gql`
          mutation ($type: String!, $where: ResourceWhereInput!, $channel: String!, $schedule: DateTime) {
            wsPublish(type: $type, where: $where, channel: $channel, schedule: $schedule) {
              ${queryBody}
            }
          }
        `,
        variables: {
          type,
          where,
          channel,
          schedule: schedule
            ? dayjs(schedule).format("YYYY-MM-DD h:mm:ss")
            : undefined,
        },
      })
      .then(({ data }) => {
        store.setPublicationData(data.wsPublish);

        const onProgressAwaited = once(() => {
          checkStatus(channel);
          dispatchPublished();
        });

        if (!schedule) {
          simulatePublishProgress((progress) => {
            set(store.publishInProgress, channel, progress);

            if (progress >= 100) {
              setTimeout(onProgressAwaited, 200);
            }
          });
        } else {
          dispatchPublished();
        }
      })
      .finally(() => {
        set(store.publishing, channel, false);
      });
  };

  const unpublish = (channel: string) => {
    set(store.unpublishing, channel, true);

    apolloClient
      .mutate({
        mutation: gql`
          mutation ($type: String!, $where: ResourceWhereInput!, $channel: String!) {
            wsUnpublish(type: $type, where: $where, channel: $channel) {
              ${queryBody}
            }
          }
        `,
        variables: {
          type,
          where,
          channel,
        },
      })
      .then(({ data }) => {
        store.setPublicationData(data.wsUnpublish);
        store.dispatch("unpublished");
        store.dispatch("updated");
      })
      .finally(() => {
        set(unpublishing.value, channel, false);
      });
  };

  const publications = computed<IPublicationItem[]>(() => {
    return store._publications.map((publication) => ({
      ...publication,
      publishInProgress:
        store.publishInProgress[publication.channel.handle] || 0,
      isPublishing: !!store.publishing[publication.channel.handle],
      isUnpublishing: !!store.unpublishing[publication.channel.handle],
    }));
  });

  const { fetching, refetching, publishing, unpublishing, loading, _status } =
    storeToRefs(store);

  return {
    fetching,
    refetching,
    publishing,
    unpublishing,
    loading,
    publications,
    status: readonly(_status),
    checkStatus,
    fetch,
    refetch,
    publish,
    unpublish,
  };
};
