<template>
  <v-chip :color="chipColor" variant="flat" size="x-small">
    {{ status }}
  </v-chip>
  <v-progress-linear
    v-if="isInProgress"
    class="mt-1"
    color="deep-orange"
    height="12"
    :model-value="publishProgress"
    rounded="lg"
    striped
  />
</template>

<script lang="ts">
import { get } from "lodash";
import { PublicationStatus } from "~/layers/admin/models";
import { alertColor, chipColor } from "./lib";

export default defineComponent({
  props: {
    status: { type: String as PropType<PublicationStatus>, required: true },
    publishProgress: { type: Number, required: true },
  },
  computed: {
    isInProgress() {
      return this.status == PublicationStatus.PUBLISHING;
    },
    chipColor(): string | undefined {
      return get(chipColor, this.status, get(alertColor, this.status));
    },
  },
});
</script>
