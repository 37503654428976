<template>
  <v-alert v-bind="alertProps" rounded="lg" variant="tonal" prominent>
    <div class="grid grid-cols-2 gap-4 md:gap-2">
      <div class="relative col-span-2 md:col-span-1">
        <div class="text-4 font-semibold">
          {{ publication.channel.name }}
        </div>
        <div class="text-3" v-if="isScheduledFor">
          Scheduled for {{ isScheduledFor }}
        </div>
        <StatusChip
          :status="publication.status"
          :publish-progress="publication.publishInProgress"
        />
        <div v-if="publication.errorMessages.length" class="mt-2">
          <v-menu open-on-click top offset-y>
            <template #activator="{ props }">
              <v-btn v-bind="props" variant="tonal">
                <Icon name="heroicons:exclamation-triangle" />
                <span class="ml-1"
                  >{{ publication.errorMessages.length }} error{{
                    publication.errorMessages.length > 1 ? "s" : ""
                  }}</span
                >
              </v-btn>
            </template>
            <v-card>
              <div class="px-4 py-2 text-subtitle-2">Has Errors</div>
              <v-divider />
              <div class="px-4 pb-2">
                <div
                  class="mt-2 text-caption"
                  v-for="(error, i) in publication.errorMessages"
                  :key="i"
                >
                  <v-icon size="small">info</v-icon> {{ error }}
                </div>
              </div>
            </v-card>
          </v-menu>
        </div>
      </div>

      <div
        class="col-span-2 md:col-span-1 flex flex-col md:flex-row md:justify-end gap-1 py-1 md:py-0"
      >
        <v-btn
          v-if="canUnpublish"
          color="secondary"
          @click="handleUnpublish"
          :disabled="disabled || publication.isUnpublishing"
          :loading="publication.isUnpublishing"
        >
          Unpublish
        </v-btn>

        <v-btn
          v-if="canSchedulePublish"
          :disabled="disabled || publication.isPublishing"
          color="secondary"
        >
          Schedule publish
          <v-menu
            v-model="scheduleMenu"
            :close-on-content-click="false"
            activator="parent"
          >
            <v-card>
              <DatetimePicker
                v-model="schedule"
                :disabled="publication.isPublishing"
                title="Pick end date/time"
                :minDate="minDate"
                time-format="24hr"
                :text-field-props="inputProps"
                ampm-in-title
              />
              <div class="px-3 py-2 flex">
                <v-spacer />
                <v-btn @click="closeScheduleMenu">Cancel</v-btn>
                <v-btn
                  v-if="schedule"
                  class="ml-2"
                  color="primary"
                  @click="publishSchedule"
                  variant="elevated"
                  >Schedule
                </v-btn>
              </div>
            </v-card>
          </v-menu>
        </v-btn>

        <v-btn
          v-if="canPublish || canPublishChanges"
          @click="handlePublish"
          :disabled="disabled || publication.isPublishing"
          :loading="publication.isPublishing"
          color="primary"
          variant="elevated"
          >Publish{{ canPublishChanges ? " changes" : "" }}</v-btn
        >
      </div>
    </div>
  </v-alert>
</template>

<script lang="ts">
import { get } from "lodash";
import dayjs from "dayjs";
import { PublicationStatus } from "~/layers/admin/models";
import { alertType, alertColor, alertIcon } from "./lib";
import StatusChip from "./StatusChip.vue";

export default defineComponent({
  props: {
    publication: { type: Object as PropType<IPublicationItem>, required: true },
    publish: { type: Function, required: true },
    unpublish: { type: Function, required: true },
    disabled: { type: Boolean, default: false },
  },
  components: { StatusChip },
  data: () => ({
    // minDate: new Date().toString(),
    minDate: dayjs().format("YYYY-MM-DD"),
    inputProps: {
      hideDetails: true,
    },
    scheduleMenu: false,
    schedule: undefined as string | undefined,
  }),
  computed: {
    alertProps(): any {
      return {
        type: this.publication.hasUnpublishedChanges
          ? "warning"
          : get(alertType, this.publication.status),
        color: get(alertColor, this.publication.status),
        icon: get(alertIcon, this.publication.status, "report_gmailerrorred"),
      };
    },
    canPublish(): boolean {
      return (
        (!!this.publication.hasUnpublishedChanges &&
          this.publication.status != PublicationStatus.ON_HOLD) ||
        // (this.publication.hasUnpublishedChanges ||
        //   this.publication.status == PublicationStatus.UNPUBLISHED)
        [PublicationStatus.UNPUBLISHED, PublicationStatus.PUBLISHING].includes(
          this.publication.status
        )
      );
    },
    canPublishChanges(): boolean {
      return (
        !!this.publication.hasUnpublishedChanges &&
        [PublicationStatus.PUBLISHED, PublicationStatus.FAILED].includes(
          this.publication.status
        )
      );
    },
    canUnpublish(): boolean {
      return [
        PublicationStatus.PUBLISHING,
        PublicationStatus.PENDING,
        PublicationStatus.FAILED,
        // PublicationStatus.IN_REVIEW,
        PublicationStatus.PUBLISHED,
        PublicationStatus.REJECTED,
      ].includes(this.publication.status);
    },
    canSchedulePublish(): boolean {
      return (
        !!this.publication.hasUnpublishedChanges ||
        this.publication.status == PublicationStatus.UNPUBLISHED
      );
    },
    isScheduledFor(): string | void {
      if (
        this.publication.schedule &&
        this.publication.status === PublicationStatus.UNPUBLISHED
      ) {
        return dayjs(this.publication.schedule).format("LLLL");
      }
    },
    isInProgress(): boolean {
      return this.publication.status == PublicationStatus.PUBLISHING;
    },
  },
  methods: {
    handlePublish() {
      this.publish(this.publication.channel.handle);
    },
    handleUnpublish() {
      this.unpublish(this.publication.channel.handle);
    },
    closeScheduleMenu() {
      this.scheduleMenu = false;
    },
    publishSchedule() {
      this.scheduleMenu = false;
      // @ts-ignore
      const schedule = this.schedule;
      if (schedule) {
        this.publish(this.publication.channel.handle, schedule);
      }
    },
  },
});
</script>
